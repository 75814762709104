<template>
  <div>
    <div class="flex w-full justify-center" v-if="loader">
      <loader></loader>
    </div>
    <div v-if="!loader">
      <table
        class="border-collapse w-full my-5"
        v-if="documents.other && documents.other.length"
      >
        <thead>
          <tr>
            <th
              class="
                p-3
                font-bold
                text-xs
                uppercase
                text-gray-600
                border-0 border-b border-solid border-gray-300
                hidden
                lg:table-cell
              "
            >
              Date
            </th>
            <th
              class="
                p-3
                font-bold
                text-xs
                uppercase
                text-gray-600
                border-0 border-b border-solid border-gray-300
                hidden
                lg:table-cell
              "
            >
              Nom
            </th>
            <th
              class="
                p-3
                font-bold
                text-xs
                uppercase
                text-gray-600
                border-0 border-b border-solid border-gray-300
                hidden
                lg:table-cell
              "
            >
              Type de document
            </th>
            <th
              class="
                p-3
                font-bold
                text-xs
                uppercase
                text-gray-600
                border-0 border-b border-solid border-gray-300
                hidden
                lg:table-cell
              "
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="
              bg-gray-100
              xl:bg-white
              flex
              lg:table-row
              flex-row
              lg:flex-row
              flex-wrap
              lg:flex-no-wrap
              mb-5
              shadow-inner
            "
            v-for="(m, i) in documents.other"
            :key="i"
          >
            <td
              class="
                w-full
                xl:w-auto
                py-5
                xl:py-3
                px-2
                xl:px-2
                text-gray-800
                border-0 border-b border-solid border-gray-200
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <div
                class="
                  block
                  xl:hidden
                  font-bold
                  text-xs
                  uppercase
                  text-gray-600
                  mb-2
                "
              >
                Date
              </div>

              <div class="font-EffraR">
                {{ moment()(m.created_at).format("DD/MM/YYYY HH:mm") }}
              </div>
            </td>
            <td
              class="
                w-full
                xl:w-auto
                py-5
                xl:py-3
                px-2
                xl:px-2
                text-gray-800
                border-0 border-b border-solid border-gray-200
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <div class="flex flex-row items-center">
                <img
                  :src="`/svg/${m.extname ? m.extname : 'warning'}.svg`"
                  class="w-8"
                />
                <h6 class="font-bold mx-3 text-base">{{ m.name }}</h6>
              </div>
            </td>
            <td
              class="
                w-full
                xl:w-auto
                py-5
                xl:py-3
                px-2
                xl:px-2
                text-gray-800
                border-0 border-b border-solid border-gray-200
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <div
                class="
                  block
                  xl:hidden
                  font-bold
                  text-xs
                  uppercase
                  text-gray-600
                  mb-2
                "
              >
                Type de document
              </div>
              <div class="font-EffraR">
                {{ m.category ? m.category.title : "--" }}
              </div>
            </td>
            <td
              class="
                w-full
                xl:w-auto
                py-5
                xl:py-3
                px-2
                xl:px-2
                text-gray-800
                border-0 border-b border-solid border-gray-200
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <div
                class="
                  block
                  xl:hidden
                  font-bold
                  text-xs
                  uppercase
                  text-gray-600
                  mb-2
                "
              >
                Actions
              </div>
              <div class="flex">
                <button
                  @click="onDownloadClick(m.uid)"
                  class="
                    flex
                    items-center
                    justify-center
                    w-full
                    bg-transparent
                    border-2 border-solid border-dokBlue-ultra
                    text-dokBlue-ultra
                    rounded-cu
                    cursor-pointer
                    px-4
                    py-2
                    ml-1
                    font-EffraR
                  "
                >
                  <i class="fas fa-eye mr-1 text-lg"></i>
                  Consulter
                </button>
                <button
                  @click="onDeleteFile(m, i)"
                  class="
                    flex
                    items-center
                    justify-center
                    w-full
                    bg-transparent
                    border-2 border-solid border-dokBlue-ultra
                    text-dokBlue-ultra
                    rounded-cu
                    cursor-pointer
                    px-4
                    py-2
                    ml-1
                    font-EffraR
                  "
                >
                  <i class="fas fa-trash mr-1 text-lg"></i>
                  Supprimer
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="flex justify-center items-center"
        v-if="documents.other && !documents.other.length"
      >
        <img src="/svg/null_data.svg" class="max-h-500" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const loader = () => import("@/views/global-components/loader");

export default {
  data() {
    return {
      documents: { other: [], prescriptions: [], resulta: [] },
      loader: true,
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.$store.dispatch("file/GET_BY_CLIENT", {
        onData: this.onOtherDocuments,
        params: {
          user: this.$route.params.id,
        },
      });
    },
    onDeleteFile(data, key) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("file/DELETE_FILE", {
              id: data._id,
              onData: ({ ok, message }) => {
                if (ok) this.documents.other.splice(key, 1);
                this.$vs.notify({
                  time: 4000,
                  text: message,
                  color: ok ? "success" : "danger",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              },
            });
          }
        },
      });
    },
    onOtherDocuments({ ok, data }) {
      if (ok) {
        this.documents.other = data;
      }
      this.loader = false;
    },
    moment() {
      return moment;
    },
    onDownloadClick(documentId) {
      window.open(
        `https://api.dok.ma/api/v1/download/document/${documentId}`,
        "_blank"
      );
    },
  },
  components: {
    loader,
  },
};
</script>

<style></style>
